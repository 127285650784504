























// Core
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Interfaces
import { Meta, Paginate, RequestParams } from '@store/interfaces'
import { User, UsersState } from '@store/users/interfaces'
import { UsersActions } from '@store/users/types'

const NSUsers = namespace('usersModule')

@Component({
  name: 'UserList',

  components: {
    'v-scroll-list': () => import('@components/ScrollList.vue'),
  },
})
export default class UserListComponent extends Vue {
  @Prop(Function)
  private handleConnectProjectToUser!: (userId: number) => void

  @NSUsers.State((state: UsersState) => state.userList) private users!: User[]

  @NSUsers.State((state: UsersState) => state.users.meta)
  private meta!: Meta

  @NSUsers.Action(UsersActions.A_GET_USERS)
  private fetchUser!: (params?: RequestParams) => Promise<Paginate<User>>

  private page: number = 1

  private created() {
    if (!this.users.length) {
      this.fetchUser({
        page: this.page,
      })
    } else {
      this.page = this.meta.currentPage
    }
  }

  private loadMore() {
    if (this.meta.lastPage > this.page) {
      this.page += 1

      this.fetchUser({
        page: this.page,
      })
    }
  }
}
